import styles from "../../styles/FarmNewField.module.scss";
import type { SetupProps } from "./types";
import Area from "../../assets/area.png";

type TAddNewFieldProps = SetupProps;

const AddNewField: React.FC<TAddNewFieldProps> = ({ page, changePage }) => {

    return (
        <div className={styles["new-field"]}>
            <div className={styles["new-field__heading"]}>
                <h2 className={styles["new-field__heading-title"]}>Add New Field</h2>
                <p className={styles["new-field__heading-description"]}>Add a new field to your farm</p>
            </div>
            <form className={styles["new-field__form"]}>
                <div className={styles["new-field__form__FirstGroup"]}>
                    <div className="form__input">
                        <label>
                            Field Name *
                        </label>
                        <input type="text" name="field-name" value="" />
                    </div>
                    <div className="form__input">
                        <label>
                            Area *
                        </label>
                        <input type="text" name="" value="" />
                    </div>
                </div>
                <div className={styles["new-field__form__SecondGroup"]}>
                    <img src={Area} />
                    <div>
                        <span>
                            <div className="form__input">
                                <label>
                                    Size (ha) *
                                </label>
                                <input type="text" name="size" value="" />
                            </div>
                            <div className="form__input">
                                <label>
                                    Select Area type *
                                </label>
                                <select name="area-definition-type"
                                    style={{ "width": "100%" }}>
                                    <option value="area-1">Area Type 1</option>
                                    <option value="area-2">Area Type 2</option>
                                </select>
                            </div>
                        </span>
                        <div className="form__input">
                            <label>
                                Area Definition Type *
                            </label>
                            <select name="area-definition">
                                <option value="area-1">Area 1</option>
                                <option value="area-2">Area 2</option>
                            </select>
                        </div>
                        <div className="form__input">
                            <label>
                                Description *
                            </label>
                            <input type="text" name="description" value="" />
                        </div>
                    </div>
                </div>
            </form>
            <div className={styles["new-field__form__buttons"]}>
                <button
                    onClick={() => changePage({ page, isForward: false })}
                    style={{
                        "border": "1px solid #538270",
                        "padding": "1.2rem 3rem",
                        "backgroundColor": "transparent",
                        "borderRadius": "1.4rem",
                        "cursor": "pointer",
                        "fontSize": "1.6rem"
                    }}
                    type="button"><strong>Cancel</strong></button>
                <button
                    onClick={() => changePage({ page, isForward: false })}
                    className="form__button"
                    style={{
                    }}
                > Add Field
                </button>
            </div>
        </div>
    )
};

export default AddNewField;