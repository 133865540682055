
import styles from "../../styles/FarmSingleFieldItem.module.scss";
import Area from "../../assets/area.png";

export default function SingleFieldItems() {

    return (
        <div className={styles["accordion-items"]}>
            <div className={styles["accordion-items__group"]}>
                <div className="form__input">
                    <label className={styles["accordion-items__item"]}>
                        Field Name
                    </label>
                    <input type="text" name="field-name" value="Ausleben-Nord" />
                </div>
                <div className="form__input">
                    <label className={styles["accordion-items__item"]}>
                        Area
                    </label>
                    <input type="text" name="area" value="Ausleben Nord" disabled />
                </div>
            </div>
            <div className="form__input">
                <label className={styles["accordion-items__item"]}>
                    Description
                </label>
                <input type="text" name="description" value="231984 (NZ)" />
            </div>
            <div className={styles["accordion-items__coordinates"]}>
                <img src={Area} />
                <div>
                    <div className="form__input">
                        <label className={styles["accordion-items__item"]}>
                            Coordinates/Polygon
                        </label>
                        <input type="text" name="coordinates" value="[234242423425...]" disabled />
                    </div>
                    <span>
                        <div className="form__input">
                            <label className={styles["accordion-items__item"]}>
                                Size (ha)
                            </label>
                            <input type="text" name="size" value="213" disabled />
                        </div>
                        <div className="form__input">
                            <label className={styles["accordion-items__item"]}>
                                Area type
                            </label>
                            <input type="text" name="area-type" value="arable" disabled />
                        </div>

                    </span>
                </div>
            </div>
        </div>
    )
}