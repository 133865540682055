import React, { createContext, useContext, useState } from 'react';
import GetStarted from '../components/setupFarm/GetStarted';
import FarmSetupInfo from '../components/setupFarm/FarmSetupInfo';
import FarmFields from '../components/setupFarm/FarmFields';
import SuccesfulImport from '../components/setupFarm/SuccessfulImport';
import AddNewField from '../components/setupFarm/AddNewField';
import styles from "../../src/styles/routes/FarmSetup.module.scss";
import farmSetupBg from "../assets/farmSetupBg.png";
import logo from "../assets/logo.svg";
import type { SetupProps } from "../components/setupFarm/types";


const FarmSetup: React.FC = () => {
    const [page, setPage] = useState("GetStarted");
    
    const pages = ["GetStarted", "FarmSetupInfo", "FarmFields", "AddNewField", "SuccessfulImport"]

    const changePage = ({page, isForward, goToIndex}:{page?:string, isForward?:boolean, goToIndex?:string}) => {
        if(goToIndex) return setPage(pages[parseInt(goToIndex, 10)])
            
        const index = pages.findIndex(p => {
            return p === page
        });
        
        if (isForward) {
            setPage(pages[index + 1])
        } else {
            setPage(pages[index - 1])
        }
    }

    return (
        <main
            className="main" 
            style={{
                margin: "-1.5rem",
                backgroundImage: `url(${farmSetupBg})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
            }}
        >   
            <section className={styles["main__section"]}>
                <div className={styles["main__section-sidebar"]}>
                    <img
                        src={logo}
                        alt="Digital Farmer"
                    />
                    <h1>
                        Digital Farmer
                    </h1>
                    <p>
                        Helping You manage Your Farm easily wherever you are.
                    </p>
                </div>
                { page === "GetStarted" &&  <GetStarted page={page} changePage={changePage}/>}
                { page === "FarmSetupInfo" &&  <FarmSetupInfo page={page} changePage={changePage} />}
                { page === "FarmFields" &&  <FarmFields page={page} changePage={changePage} />}
                { page === "SuccessfulImport" &&  <SuccesfulImport page={page} changePage={changePage} />}
                { page === "AddNewField" &&  <AddNewField page={page} changePage={changePage} />}
            </section>
        </main>
    )
};

export default FarmSetup;




