import { createContext, ReactNode, useReducer } from "react";

interface ThemeState {
  user: {
    accessToken: string | null;
  };
}

interface SetAccessToken {
  type: "SET_TOKEN";
  payload: { accessToken: string };
}

const initialState: ThemeState = { user: { accessToken: null } };

export const ThemeContext = createContext<
  { state: ThemeState; setToken: (accessToken: string) => void } | undefined
>(undefined);

export function ThemeContextProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(themeReducer, initialState);

  const setToken = (accessToken: string) => {
    dispatch({ type: "SET_TOKEN", payload: { accessToken } });
  };

  return (
    <>
      <ThemeContext.Provider
        value={{
          state,
          setToken,
        }}
      >
        {children}
      </ThemeContext.Provider>
    </>
  );
}

function themeReducer(state: ThemeState, action: SetAccessToken): ThemeState {
  switch (action.type) {
    case "SET_TOKEN":
      return { ...state, user: { accessToken: action.payload.accessToken } };
    default:
      return state;
  }
}
