import { ButtonHTMLAttributes, CSSProperties, ReactNode } from "react";

import styles from "../../styles/Form.module.scss";

export function Form({
  children,
  isInline = false,
  customClassName,
  customCSS,
}: {
  children: ReactNode;
  isInline?: boolean;
  customClassName?: string;
  customCSS?: CSSProperties;
}) {
  return (
    <>
      <form
        className={`${styles.form ? styles.form : ""} ${
          customClassName ? customClassName : ""
        } ${isInline ? styles["form--inline"] : ""}`}
        style={customCSS}
      >
        {children}
      </form>
    </>
  );
}

export function FormGroup({
  children,
  isRow,
  customClassName,
  customCSS,
}: {
  children: ReactNode;
  isRow?: boolean;
  customClassName?: string;
  customCSS?: CSSProperties;
}) {
  return (
    <>
      <div
        className={`${styles.form__group} ${
          customClassName ? customClassName : ""
        } ${isRow ? styles["form__group-row"] : ""}`}
        style={customCSS}
      >
        {children}
      </div>
    </>
  );
}

export function FormInput({
  id,
  name,
  type = "text",
  label,
  placeholder,
  isRequired = false,
  customCSS,
}: {
  id: string;
  name: string;
  type?: string;
  label?: string;
  placeholder?: string;
  isRequired?: boolean;
  customCSS?: CSSProperties;
}) {
  return (
    <>
      <div className={styles.form__input} style={customCSS}>
        {label && (
          <label className={styles.form__label} htmlFor={id}>
            {label}
          </label>
        )}
        <div
          className={
            type && type === "date" ? styles["form__input__date-wrapper"] : ""
          }
        >
          <input
            id={id}
            name={name}
            type={type}
            required={isRequired}
            placeholder={placeholder}
          />
        </div>
      </div>
    </>
  );
}

export function FormTextarea({
  id,
  name,
  label,
  value,
  isRequired = false,
  customCSS,
}: {
  id: string;
  name: string;
  label?: string;
  value?: string;
  isRequired?: boolean;
  customCSS?: CSSProperties;
}) {
  return (
    <>
      <div className={styles.form__textarea} style={customCSS}>
        {label && (
          <label className={styles.form__label} htmlFor={id}>
            {label}
          </label>
        )}
        <textarea id={id} name={name} required={isRequired}>
          {value}
        </textarea>
      </div>
    </>
  );
}

export function ForomSelect({
  id,
  name,
  label,
  selectOptions,
  isRequired = false,
  customCSS,
}: {
  id: string;
  name: string;
  label: string;
  selectOptions: Array<string>;
  isRequired?: boolean;
  customCSS?: CSSProperties;
}) {
  return (
    <>
      <div className={styles.form__select} style={customCSS}>
        <label className={styles.form__label} htmlFor={id}>
          {label}
        </label>
        <select id={id} name={name} required={isRequired}>
          {selectOptions.map((optionValue, index) => (
            <option key={index} value={optionValue}>
              {optionValue}
            </option>
          ))}
        </select>
      </div>
    </>
  );
}

export function FormButton({
  children,
  type = "submit",
  action,
  isTransparent,
  customCSS,
}: {
  children: string;
  type?: ButtonHTMLAttributes<HTMLButtonElement>["type"];
  action?: () => void;
  isTransparent?: boolean;
  customCSS?: CSSProperties;
}) {
  return (
    <>
      <button
        className={`${styles.form__button} ${
          isTransparent ? styles["form__button--transparent"] : ""
        }`}
        style={customCSS}
        type={type}
        {...(action && { onClick: action })}
      >
        {children}
      </button>
    </>
  );
}
