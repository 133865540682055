import { CSSProperties, ReactNode } from "react";

import styles from "../../styles/Slider.module.scss";

export default function Slider({
  children,
  customClassName,
  customStyle,
}: {
  children: ReactNode;
  customClassName?: string;
  customStyle?: CSSProperties;
}) {
  return (
    <>
      <div
        className={`${styles.slider} ${customClassName}`}
        style={customStyle}
      >
        <div className={styles.slider__inner}>{children}</div>
      </div>
    </>
  );
}
