import { memo, useEffect, useState } from "react";

import PageHeading from "../components/page/Heading";
import SectionWrapper from "../components/section/SectionWrapper";
import SectionHeading from "../components/section/SectionHeading";
import TaskList from "../components/list/TaskList";
import {
  Form,
  FormInput,
  FormGroup,
  FormButton,
} from "../components/form/Form";

import pageIcon from "../assets/icons/files.svg";
import alertIcon from "../assets/icons/alert.svg";

import styles from "../styles/routes/tasks.module.scss";

const FirstColContent = memo(function FirstColContent() {
  return (
    <>
      <SectionWrapper>
        <SectionHeading title="Tasks To Do" label="Today" />
        <TaskList />
      </SectionWrapper>
    </>
  );
});

const SecondColContent = memo(function FirstColContent() {
  return (
    <>
      <SectionWrapper>
        <SectionHeading title="Tasks To Do" label="Today" />
        <TaskList />
      </SectionWrapper>
      <SectionWrapper>
        <SectionHeading title="Tasks Overdue" icon={alertIcon} />
        <TaskList isOverdue={true} showDate={true} />
      </SectionWrapper>
    </>
  );
});

export default function Tasks() {
  const [isMobile, setIsMobile] = useState(false);

  const observer = new ResizeObserver(() => {
    if (window.innerWidth <= 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  });

  useEffect(() => {
    observer.observe(document.body);
  }, []);

  return (
    <>
      <main className={styles.main}>
        <PageHeading
          pageTitle="Tasks"
          pageIcon={pageIcon}
          action={() => {}}
          actionTitle="New Task"
        />
        <div className={styles.main__filters}>
          <Form
            isInline={true}
            customCSS={{
              gap: "1rem",
            }}
          >
            <FormGroup>
              <FormInput id="field" name="field" placeholder="Field" />
            </FormGroup>
            <FormGroup>
              <FormInput
                id="task-type"
                name="task-type"
                placeholder="Task Type"
              />
            </FormGroup>
            <FormGroup>
              <FormInput
                id="crop-zone"
                name="crop-zone"
                placeholder="Crop Zone"
              />
            </FormGroup>
            <FormGroup>
              <FormInput id="name" name="name" placeholder="Name" />
            </FormGroup>
            <FormGroup>
              <FormInput
                id="track-status"
                name="track-status"
                placeholder="Track Status"
              />
            </FormGroup>
            <FormGroup
              customCSS={{
                flexGrow: 0,
              }}
            >
              <FormButton>Go</FormButton>
            </FormGroup>
          </Form>
        </div>
        {isMobile ? (
          <>
            <FirstColContent />
            <SecondColContent />
          </>
        ) : (
          <>
            <div className={styles["main__first-col"]}>
              <FirstColContent />
            </div>
            <div className={styles["main__second-col"]}>
              <SecondColContent />
            </div>
          </>
        )}
      </main>
    </>
  );
}
