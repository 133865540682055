import { useState } from "react";

import notificationIcon from "../../assets/icons/notification/notification.svg";

import styles from "../../styles/Notification.module.scss";

export default function Notification() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div className={styles.notification}>
        <button
          className={styles.notification__info}
          type="button"
          onClick={() => setIsOpen(true)}
        >
          <img
            className={styles.notification__icon}
            src={notificationIcon}
            alt="Notifications"
          />
          <span className={styles.notification__label}>15</span>
        </button>
        <div
          className={`${styles.notification__sidemenu} ${
            isOpen ? styles["notification__sidemenu--is-open"] : ""
          }`}
        >
          <div className={styles.notification__sidemenu__heading}>
            <img
              className={styles.notification__sidemenu__icon}
              src={notificationIcon}
              alt="Notification List Icon"
            />
            <span className={styles.notification__sidemenu__title}>
              Notifications
            </span>
            <button
              className={styles["notification__sidemenu__close-btn"]}
              type="button"
              onClick={() => setIsOpen(false)}
            >
              X
            </button>
          </div>
          <ul className={styles.notification__list}>
            <li className={styles.notification__list__item}>
              <img
                className={styles.notification__list__icon}
                src={notificationIcon}
                alt="Notification Icon"
              />
              <span className={styles.notification__list__title}>
                Finish your ESG Questionnaire for 2024
              </span>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
