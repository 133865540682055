import { CSSProperties } from "react";

import pinIcon from "../../assets/icons/pin.svg";

import styles from "../../styles/LocationLabel.module.scss";

export default function LocationLabel({
  locationName,
  customClassName,
  customStyle,
}: {
  locationName: string;
  customClassName?: string;
  customStyle?: CSSProperties;
}) {
  return (
    <>
      <div
        className={`${styles.location} ${customClassName}`}
        style={customStyle}
      >
        <img
          className={styles.location__icon}
          src={pinIcon}
          alt={locationName}
        />
        <div className={styles.location__title}>{locationName}</div>
      </div>
    </>
  );
}
