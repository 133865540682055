import { ReactNode } from "react";

import styles from "../../styles/MapStateLabel.module.scss";

export default function MapStateLabel({
  children,
  state,
}: {
  children: ReactNode;
  state?: boolean;
}) {
  return (
    <>
      <div
        className={`${styles["map-state"]} ${
          !state ? styles["map-state--alert"] : ""
        }`}
      >
        <span className={styles["map-state__label"]}>{children}</span>
      </div>
    </>
  );
}
