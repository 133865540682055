import { Link } from "react-router-dom";
import styles from "../../styles/SectionHeading.module.scss";

import linkIcon from "../../assets/icons/seeAllArr.svg";

export default function SectionHeading({
  title,
  label,
  action,
  icon,
}: {
  title: string;
  label?: string;
  action?: () => void;
  icon?: string;
}) {
  return (
    <>
      <div className={styles.heading}>
        <h2 className={styles.heading__title}>
          {icon && (
            <img className={styles.heading__icon} src={icon} alt={title} />
          )}{" "}
          {title} {label && <span>label</span>}
        </h2>
        {action && (
          <button className={styles.heading__link} onClick={action}>
            <span>See All</span>
            <img src={linkIcon} alt="See All" />
          </button>
        )}
      </div>
    </>
  );
}
