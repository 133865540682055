import {
  Form,
  FormInput,
  FormGroup,
  FormButton,
  FormTextarea,
  ForomSelect,
} from "./Form";

import styles from "./NewFormTask.module.scss";

export default function NewTaskForm() {
  return (
    <>
      <Form customClassName={styles["custom-form"]}>
        <FormGroup
          isRow={true}
          customCSS={{
            gridColumn: "span 2",
            margin: 0,
          }}
        >
          <FormInput
            id="task-name"
            name="task-name"
            label="Task Name"
            isRequired={true}
          />
          <ForomSelect
            id="work-order"
            name="work-order"
            label="Work Order"
            selectOptions={["Option 1", "Option 2", "Option 3"]}
            isRequired={true}
          />
        </FormGroup>
        <FormGroup
          isRow={true}
          customCSS={{
            gridColumn: "span 2",
            margin: 0,
          }}
        >
          <ForomSelect
            id="status"
            name="status"
            label="Status"
            selectOptions={["Option 1", "Option 2", "Option 3"]}
            isRequired={true}
          />
          <ForomSelect
            id="assignee"
            name="assignee"
            label="Assignee"
            selectOptions={["Option 1", "Option 2", "Option 3"]}
            isRequired={true}
          />
        </FormGroup>
        <FormGroup
          isRow={true}
          customCSS={{
            gridColumn: "span 2",
            margin: 0,
          }}
        >
          <ForomSelect
            id="priority"
            name="priority"
            label="Priority"
            selectOptions={["Option 1", "Option 2", "Option 3"]}
            isRequired={true}
          />
          <ForomSelect
            id="task-type"
            name="task-type"
            label="Task Type"
            selectOptions={["Option 1", "Option 2", "Option 3"]}
            isRequired={true}
          />
        </FormGroup>
        <FormGroup
          isRow={true}
          customCSS={{
            gridColumn: "span 2",
            gridRow: "span 2",
            margin: 0,
          }}
        >
          <FormTextarea
            id="description"
            name="description"
            label="Description"
            isRequired={true}
          />
          <ForomSelect
            id="farm"
            name="farm"
            label="Farm"
            selectOptions={["Option 1", "Option 2", "Option 3"]}
            isRequired={true}
          />
        </FormGroup>
        <FormGroup
          isRow={true}
          customCSS={{
            gridColumn: "span 2",
            margin: 0,
          }}
        >
          <ForomSelect
            id="field"
            name="field"
            label="Field"
            selectOptions={["Option 1", "Option 2", "Option 3"]}
            isRequired={true}
          />
          <ForomSelect
            id="crop-zone"
            name="crop-zone"
            label="Crop Zone"
            selectOptions={["Option 1", "Option 2", "Option 3"]}
            isRequired={true}
          />
        </FormGroup>
        <FormGroup
          isRow={true}
          customCSS={{
            gridColumn: 1,
            flexDirection: "row",
            margin: 0,
          }}
        >
          <FormInput
            id="start-date"
            name="start-date"
            type="date"
            label="Start Date"
            isRequired={true}
            customCSS={{
              flex: 1,
            }}
          />
          <FormInput
            id="end-date"
            name="end-date"
            type="date"
            label="End Date"
            isRequired={true}
            customCSS={{
              flex: 1,
            }}
          />
        </FormGroup>
        <FormGroup
          customClassName={styles["custom-form__button"]}
          customCSS={{
            margin: 0,
            display: "flex",
            alignItems: "flex-end",
          }}
        >
          <FormButton>Create Task</FormButton>
        </FormGroup>
      </Form>
    </>
  );
}
