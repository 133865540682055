import Map from "../map/Map";
import MapStateLabel from "../map/MapStateLabel";
import L from "leaflet";

import styles from "../../styles/FieldCardSm.module.scss";

export default function FieldCardSm({
  label,
  area,
  markers,
  zoomControl = false,
}: {
  label: string;
  area?:
    | L.LatLngExpression[]
    | L.LatLngExpression[][]
    | L.LatLngExpression[][][];
  markers?: Array<L.LatLngExpression>;
  zoomControl?: boolean;
}) {
  return (
    <>
      <div className={styles.field}>
        <div className={styles.field__media}>
          <Map area={area} markers={markers} zoomControl={zoomControl} />
          <MapStateLabel state={false}>Need Nutrients</MapStateLabel>
        </div>
        <div className={styles.field__heading}>
          <span className={styles.field__title}>Grapes F1</span>
          <span className={styles.field__subtitle}>1,6 Ha</span>
        </div>
      </div>
    </>
  );
}
