import { ReactNode, CSSProperties } from "react";

import closeIcon from "../../assets/icons/close.svg";

import styles from "./Modal.module.scss";

export default function Modal({
  children,
  headingTitle,
  headingSubtitle,
  headingIcon,
  size = "lg",
  isOpen,
  onClose,
  actionButtonTitle,
  actionButtonFn,
  customClassName,
  customStyle,
}: {
  children?: ReactNode;
  headingTitle: string;
  headingSubtitle?: string;
  headingIcon?: string;
  size?: string;
  isOpen: boolean;
  onClose: () => void;
  actionButtonTitle?: string;
  actionButtonFn?: () => void;
  customClassName?: string;
  customStyle?: CSSProperties;
}) {
  if (!isOpen) return <></>;

  return (
    <>
      <div className={styles.overlay}>
        <div
          className={`${styles.modal} ${size ? styles["modal--" + size] : ""} ${
            customClassName ? customClassName : ""
          }`}
          style={customStyle}
        >
          <div className={`${styles.modal__heading}`}>
            {headingIcon && (
              <div className={styles.modal__heading__icon}>
                <img src={headingIcon} alt={headingTitle} />
              </div>
            )}
            <div className={styles.modal__heading__content}>
              <h2 className={styles.modal__heading__title}>{headingTitle}</h2>
              {headingSubtitle && (
                <p className={styles.modal__heading__subtitle}>
                  {headingSubtitle}
                </p>
              )}
            </div>
            <div className={styles.modal__heading__actions}>
              {actionButtonTitle && actionButtonFn && (
                <button
                  className={styles["modal__heading__action-button"]}
                  type="button"
                >
                  {actionButtonTitle}
                </button>
              )}
              <button
                className={styles.modal__heading__button}
                onClick={onClose}
                type="button"
              >
                <img src={closeIcon} alt="Close modal" />
              </button>
            </div>
          </div>
          <div>{children}</div>
        </div>
      </div>
    </>
  );
}
