import {
  Dispatch,
  memo,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import SectionWrapper from "./components/section/SectionWrapper";
import SectionHeading from "./components/section/SectionHeading";
import Weather from "./components/weather/Weather";
import TaskList from "./components/list/TaskList";
import Map from "./components/map/Map";
import MapStateLabel from "./components/map/MapStateLabel";
import LocationLabel from "./components/location/LocationLabel";
import Slider from "./components/slider/Slider";
import FieldCardSm from "./components/card/FieldCardSm";
import Heading from "./components/page/Heading";
import Modal from "./components/modal/Modal";
import NewTaskForm from "./components/form/NewTaskForm";
import Pagination from "./components/pagination/Pagination";
import { useFetch } from "./hooks/useFetch";
import { ThemeContext } from "./useContext/context";

import pageIcon from "./assets/icons/home.svg";

import styles from "./styles/routes/home.module.scss";

const FirstColContent = memo(function FirstColContent({
  setIsTasksModalOpen,
}: {
  setIsTasksModalOpen?: Dispatch<SetStateAction<boolean>>;
}) {
  return (
    <>
      <SectionWrapper
        customClassName={styles.main__weather__section}
        customStyle={{
          border: 0,
        }}
      >
        <Weather />
      </SectionWrapper>
      <SectionWrapper>
        <SectionHeading
          title="Tasks To Do"
          label="Today"
          action={() => setIsTasksModalOpen!(true)}
        />
        <TaskList />
      </SectionWrapper>
    </>
  );
});

const SecondColContent = memo(function SecondColContent({
  setIsMyFieldsModalOpen,
}: {
  setIsMyFieldsModalOpen?: Dispatch<SetStateAction<boolean>>;
}) {
  return (
    <>
      <SectionWrapper customClassName={styles.main__fields__section}>
        <SectionHeading
          title="My Fields"
          action={() => setIsMyFieldsModalOpen!(true)}
        />
        <div className={styles.main__fields}>
          <Slider customClassName={styles.main__fields__slider}>
            <div className={`${styles.main__fields__field} ${styles.field}`}>
              <div className={styles.field__media}>
                <Map
                  area={[
                    [45.10945030797108, 19.884384990622966],
                    [46.10945030797108, 23.884384990622966],
                    [47.10945030797108, 21.884384990622966],
                    [51.10945030797108, 25.884384990622966],
                  ]}
                />
                <MapStateLabel state={false}>Need Nutrients</MapStateLabel>
              </div>
              <div className={styles.field__heading}>
                <span className={styles.field__title}>Grapes F1</span>
                <span className={styles.field__subtitle}>1,6 Ha</span>
              </div>
            </div>
            <div className={`${styles.main__fields__field} ${styles.field}`}>
              <div className={styles.field__media}>
                <Map
                  markers={[
                    [45.10945030797108, 19.884384990622966],
                    [46.10945030797108, 19.884384990622966],
                  ]}
                />
                <MapStateLabel state={true}>Need Nutrients</MapStateLabel>
              </div>
              <div className={styles.field__heading}>
                <span className={styles.field__title}>Grapes F1</span>
                <span className={styles.field__subtitle}>1,6 Ha</span>
              </div>
            </div>
            <div className={`${styles.main__fields__field} ${styles.field}`}>
              <div className={styles.field__media}>
                <Map />
                <MapStateLabel state={true}>Need Nutrients</MapStateLabel>
              </div>
              <div className={styles.field__heading}>
                <span className={styles.field__title}>Grapes F1</span>
                <span className={styles.field__subtitle}>1,6 Ha</span>
              </div>
            </div>
          </Slider>
        </div>
      </SectionWrapper>
      <SectionWrapper
        customClassName={styles.main__map__section}
        customStyle={{ flex: 1, padding: 0, border: 0 }}
      >
        <Map
          markers={[
            [45.10945030797108, 19.884384990622966],
            [46.10945030797108, 19.884384990622966],
          ]}
          zoomControl={true}
        />
        <LocationLabel
          locationName="Giri Purno"
          customStyle={{
            position: "absolute",
            top: "1.5rem",
            left: "2rem",
          }}
        />
      </SectionWrapper>
    </>
  );
});

function App() {
  const context = useContext(ThemeContext);
  const [isMobile, setIsMobile] = useState(false);

  const [isNewTaskModalOpen, setIsNewTaskModalOpen] = useState(false);
  const [isTasksModalOpen, setIsTasksModalOpen] = useState(false);
  const [isMyFieldsModalOpen, setIsMyFieldsModalOpen] = useState(false);

  const observer = new ResizeObserver(() => {
    if (window.innerWidth <= 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  });

  useEffect(() => {
    observer.observe(document.body);

    // useFetch({
    //   path: "/Areas?$top=2&$skip=0&$count=true",
    //   region: "eu10-004",
    //   service: "catalog",
    // });

    // console.log(context?.state.user.accessToken);
  }, []);

  return (
    <>
      <main className={styles.main}>
        <Heading
          pageTitle="Home"
          pageIcon={pageIcon}
          actionTitle="New Task"
          action={() => setIsNewTaskModalOpen(true)}
        />
        {isMobile ? (
          <>
            <FirstColContent
              setIsTasksModalOpen={() => setIsTasksModalOpen(true)}
            />
            <SecondColContent setIsMyFieldsModalOpen={setIsMyFieldsModalOpen} />
          </>
        ) : (
          <>
            <div className={styles["main__first-col"]}>
              <FirstColContent setIsTasksModalOpen={setIsTasksModalOpen} />
            </div>
            <div className={styles["main__second-col"]}>
              <SecondColContent
                setIsMyFieldsModalOpen={setIsMyFieldsModalOpen}
              />
            </div>
          </>
        )}
      </main>

      <Modal
        headingTitle="My Fields"
        headingSubtitle="Here is a complete list of your Fields"
        actionButtonTitle="New Field"
        actionButtonFn={() => {}}
        isOpen={isMyFieldsModalOpen}
        onClose={() => setIsMyFieldsModalOpen(false)}
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(23rem, 1fr)",
            gap: "var(--form-gr-mb)",
          }}
        >
          <FieldCardSm
            label="Need Nutrients"
            area={[
              [45.10945030797108, 19.884384990622966],
              [46.10945030797108, 23.884384990622966],
              [47.10945030797108, 21.884384990622966],
              [51.10945030797108, 25.884384990622966],
            ]}
          />
          <FieldCardSm
            label="Need Nutrients"
            area={[
              [45.10945030797108, 19.884384990622966],
              [46.10945030797108, 23.884384990622966],
              [47.10945030797108, 21.884384990622966],
              [51.10945030797108, 25.884384990622966],
            ]}
          />
          <FieldCardSm
            label="Need Nutrients"
            area={[
              [45.10945030797108, 19.884384990622966],
              [46.10945030797108, 23.884384990622966],
              [47.10945030797108, 21.884384990622966],
              [51.10945030797108, 25.884384990622966],
            ]}
          />
          <FieldCardSm
            label="Need Nutrients"
            area={[
              [45.10945030797108, 19.884384990622966],
              [46.10945030797108, 23.884384990622966],
              [47.10945030797108, 21.884384990622966],
              [51.10945030797108, 25.884384990622966],
            ]}
          />
        </div>
        <Pagination />
      </Modal>

      <Modal
        headingTitle="Tasks Overdue"
        isOpen={isTasksModalOpen}
        onClose={() => setIsTasksModalOpen(false)}
      >
        <TaskList showDate={true} />
      </Modal>

      <Modal
        headingTitle="Create New Task"
        headingIcon={pageIcon}
        isOpen={isNewTaskModalOpen}
        onClose={() => setIsNewTaskModalOpen(false)}
      >
        <NewTaskForm />
      </Modal>
    </>
  );
}

export default App;
