import { useState } from "react";
import styles from "../../styles/FarmSetupFields.module.scss";
import arrDown from "../../assets/icons/arrDown.svg";
import deleteIcon from "../../assets/icons/delete.svg";
import SingleFieldItems from "./SingleFieldItems";

export default function SingleField() {
    const [isOpen, setIsOpen] = useState(false);
    console.log(isOpen)

    return (
        <>
            <input className={styles["single-field__checkbox"]} name="field" type="checkbox" />
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect width="24" height="24" rx="4" />
                <path d="M16.6668 8.79163L10.2502 15.2083L7.3335 12.2916" />
            </svg>
            <div className={styles["single-field"]}>
                <div className={styles["form__input"]}>
                    <span className={styles["single-field__accordion-heading"]}>
                        <h2>Auskeben Nord</h2>
                        <button
                            type="button"
                            onClick={() => setIsOpen(true)}
                        >
                            <img
                                src={arrDown}
                                className={styles["single-field__accordion-arrow"]}
                                alt="Arr Down"
                            />
                        </button>
                    </span>
                    <div className={`${styles["single-field__is-closed"]} ${isOpen ? styles["single-field__is-opened"] : "" }`}>
                        <SingleFieldItems />
                    </div>
                </div>
            </div>
            <img className={styles["single-field__delete"]} src={deleteIcon} alt="Delete" />

        </>
    )
}