import { useEffect, useRef } from "react";
import L from "leaflet";

import markerIcon from "../../assets/icons/map/marker.svg";

import styles from "../../styles/Map.module.scss";
import "leaflet/dist/leaflet.css";

export default function Map({
  area,
  markers,
  zoomControl,
}: {
  area?:
    | L.LatLngExpression[]
    | L.LatLngExpression[][]
    | L.LatLngExpression[][][];
  markers?: Array<L.LatLngExpression>;
  zoomControl?: boolean;
}) {
  const mapRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (mapRef.current) {
      const map = L.map(mapRef.current, {
        center: [45.10945030797108, 19.884384990622966],
        zoom: 15,
        zoomControl: false,
      });

      if (area) {
        const layerGroup = new L.FeatureGroup();

        map.addLayer(layerGroup);

        L.polygon(area, {
          color: "white",
          fillColor: "white",
          fillOpacity: 0.5,
        }).addTo(layerGroup);
      }

      if (markers) {
        const layerGroup = L.featureGroup();

        map.addLayer(layerGroup);

        markers.forEach((marker) => {
          L.marker(marker, { icon: L.icon({ iconUrl: markerIcon }) })
            .bindTooltip("Test", {
              permanent: true,
              direction: "right",
              offset: L.point(-45, 12),
              className: "leaflet-marker-tooltip",
            })
            .addTo(layerGroup);
        });
      }

      if (zoomControl) {
        L.control
          .zoom({
            position: "bottomright",
          })
          .addTo(map);
      }

      // https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png
      // © OpenStreetMap contributors
      L.tileLayer(
        "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
        {
          attribution:
            'Earthstar Geographics | Powered by <a href="https://www.esri.com/" rel="noreferrer" target="_blank">Esri</a>',
        }
      ).addTo(map);

      return () => {
        map.remove();
      };
    }
  }, []);

  return (
    <>
      <div className={styles.map} ref={mapRef}></div>
    </>
  );
}
