import LocationLabel from "../location/LocationLabel";

import cloudyIcon from "../../assets/icons/weather/cloudy.svg";

import styles from "../../styles/Weather.module.scss";

export default function Weather() {
  return (
    <>
      <div className={styles["weather"]}>
        <div className={styles["weather__info"]}>
          <div className={styles["weather__temperature"]}>24°</div>
          <LocationLabel
            locationName="Giri Purno"
            customClassName={styles["weather__location"]}
          />
          <p className={styles["weather__comment"]}>
            Today is partly sunny day!
          </p>
        </div>
        <img
          className={styles["weather__icon"]}
          src={cloudyIcon}
          alt="Cloudy weather"
        />
        <div className={styles["weather__additional"]}>
          <div className={styles["weather__additional__item"]}>
            <p className={styles["weather__additional__title"]}>77%</p>
            <p className={styles["weather__additional__subtitle"]}>Humidity</p>
          </div>
          <div className={styles["weather__additional__item"]}>
            <p className={styles["weather__additional__title"]}>&lt; 0.1 mm</p>
            <p className={styles["weather__additional__subtitle"]}>
              Precipitation
            </p>
          </div>
          <div className={styles["weather__additional__item"]}>
            <p className={styles["weather__additional__title"]}>6 km/h</p>
            <p className={styles["weather__additional__subtitle"]}>
              Wind Speed
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
