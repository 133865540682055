import { ReactNode, CSSProperties } from "react";

import styles from "../../styles/SectionWrapper.module.scss";

export default function SectionWrapper({
  children,
  customClassName,
  customStyle,
}: {
  children?: ReactNode;
  customClassName?: string;
  customStyle?: CSSProperties;
}) {
  return (
    <>
      <section
        className={`${styles.section} ${customClassName}`}
        style={customStyle}
      >
        {children}
      </section>
    </>
  );
}
