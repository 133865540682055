import styles from "../../styles/FarmSetupInfo.module.scss";
import SetupHeading from "./SetupHeading";
import type { SetupProps } from "./types";

type TFarmInfoProps = SetupProps;

const FarmSetupInfo: React.FC<TFarmInfoProps> = ({ page, changePage }) => {

    return (
        <div className={styles["setup-info"]}>
            <SetupHeading page={page} changePage={changePage} />
            <form action="" className={styles["setup-info__form"]}>
                <div className="form__input">
                    <label className={styles["accordion-items__item"]}>
                        Farm Name
                    </label>
                    <input type="text" name="farm-name" value="Max Mustermann Farm" />
                </div>
                <div className="form__input">
                    <label className={styles["accordion-items__item"]}>
                        District
                    </label>
                    <input type="text" name="district" value="Warsleben" />
                </div>                
                <div className="form__input">
                    <label className={styles["accordion-items__item"]}>
                        Description
                    </label>
                    <textarea value = "Ausleben Farm NZ"> </textarea>
                </div>
                <div className="form__input">
                    <label className={styles["accordion-items__item"]}>
                        Location
                    </label>
                    <input type="text" name="location" value="Germany" disabled />
                </div>
                <div className="form__input">
                    <label className={styles["accordion-items__item"]}>
                        Your Business Partnet
                    </label>
                    <input type="text" name="business-partnet" value="test.test@gmail.com" disabled />
                </div>
            </form>
            <button
                onClick={() => changePage({page, isForward:true})}
                className="form__button"
            >
                Continue
            </button>
        </div>
    )
};

export default FarmSetupInfo;

