import styles from "../../styles/TaskList.module.scss";

import eyeIcon from "../../assets/icons/eye.svg";
import locationIcon from "../../assets/icons/pin.svg";

export default function TaskList({
  isOverdue,
  showDate,
}: {
  isOverdue?: boolean;
  showDate?: boolean;
}) {
  const taskName = "Crop Rotation";
  const area = "Wheat F3";
  const time = "10:30 AM";

  return (
    <>
      <ul className={styles["task-list"]}>
        <li
          className={`${styles["task-list__item"]} ${
            status ? styles[`task-list__item--${status}`] : ""
          }`}
        >
          {showDate && (
            <div className={`${styles["task-list__date"]}`}>
              <p className={`${styles["task-list__date__month"]}`}>Jun</p>
              <p className={`${styles["task-list__date__day"]}`}>15</p>
            </div>
          )}
          <div
            className={`${styles["task-list__content"]} ${
              isOverdue ? styles["task-list__content--overdue"] : ""
            }`}
          >
            <div className={`${styles["task-list__checkbox"]}`}>
              <input id="task" name="task" type="checkbox" />
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="24" height="24" rx="4" />
                <path d="M16.6668 8.79163L10.2502 15.2083L7.3335 12.2916" />
              </svg>
            </div>
            <div className={`${styles["task-list__heading"]}`}>
              <span className={styles["task-list__heading__title"]}>
                {taskName}
              </span>
              {area && (
                <span className={styles["task-list__heading__subtitle"]}>
                  {locationIcon && (
                    <img src={locationIcon} alt="Location pin" />
                  )}
                  {area}
                </span>
              )}
            </div>
            {time && (
              <div className={`${styles["task-list__info"]}`}>
                <span>{time}</span>
              </div>
            )}
            {isOverdue && (
              <button
                className={`${styles["task-list__button"]}`}
                type="button"
              >
                <img src={eyeIcon} alt="See the task" />
              </button>
            )}
          </div>
        </li>
      </ul>
    </>
  );
}
